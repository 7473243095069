import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import produce from "immer";
import { fetchPost } from "./postAPI";
import { formatISO } from "date-fns";

export const Statuses = {
  Initial: "Not Fetched",
  Loading: "Loading..",
  UpToDate: "Up To Date",
  Deleted: "Deleted",
  Error: "Error",
};

export const initialLikeState = {
  id: 0,
  post_id: 0,
  user_id: 0,
};

export const initialBidState = {
  id: 0,
  post_id: 0,
  user_id: 0,
  price: 0,
  avatar_url: "",
  created_at: Date.now(),
};

export const initialCommentState = {
  id: 0,
  post_id: 0,
  user_id: 0,
  body: "",
  avatar_url: "",
  created_at: Date.now(),
};

export const initialShiftState = {
  id: 0,
  position: "",
  start: Date.now(),
  end: Date.now(),
};

export const initialState = {
  post: {
    id: 0,
    body: "",
    user_id: 0,
    ends_at: Date.now(),
    created_at: formatISO(Date.now()),
    updated_at: "",
    avatar_url: "",
    bids: [initialBidState],
    likes: [initialLikeState],
    shifts: [initialShiftState],
    comments: [initialCommentState],
    post_admins: [{ id: 0 }],
    hide: false,
  },

  status: Statuses.Initial,

  currentPostId: 0,
};

export const fetchPostAsync = createAsyncThunk(
  "posts/fetchPost",
  async (postId) => {
    const response = await fetchPost(postId);
    return response;
  }
);

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes.
      // Also, no return statement is required from these functions.
      state.value += 1;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // while you wait
      .addCase(fetchPostAsync.pending, (state) => {
        return produce(state, (draftState) => {
          draftState.status = Statuses.Loading;
        });
      })
      // you got the thing
      .addCase(fetchPostAsync.fulfilled, (state, action) => {
        return produce(state, (draftState) => {
          draftState.currentPostId = action.payload.id;
          draftState.post = action.payload;
          draftState.status = Statuses.UpToDate;
        });
      })
      // error
      .addCase(fetchPostAsync.rejected, (state) => {
        return produce(state, (draftState) => {
          draftState.status = Statuses.Error;
        });
      });
  },
});

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = postSlice.actions;

export const selectPost = (state) => state.posts.post;

export const selectStatus = (state) => state.posts.status;

export const selectCurrentPostId = (state) => state.posts.currentPostId;

export default postSlice.reducer;
